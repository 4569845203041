import React, { createContext, useState } from "react";

const PSSWRD = "studioascenti";

export const PassProtectionContext = createContext({ passwordIsTrue: null });

const PassProtectionProvider = ({ children }) => {
  const [passwordIsTrue, setPasswordIsTrue] = useState(null);

  const tryPassword = password => {
    if (password === PSSWRD) {
      setPasswordIsTrue(true);
    } else {
      setPasswordIsTrue(false);
    }
  };

  const ctx = {
    passwordIsTrue,
    tryPassword,
  };

  return (
    <PassProtectionContext.Provider value={ctx}>
      {children}
    </PassProtectionContext.Provider>
  );
};

export default PassProtectionProvider;
