import React, { useContext, useEffect } from "react";
import { Global, css } from "@emotion/react";
import { LoadContext } from "@providers/LoadProvider";
import WhyteInkTrapWoff from "@fonts/whyteinktrap-regular.woff";
import WhyteInkTrapWoff2 from "@fonts/whyteinktrap-regular.woff2";
import MonclerGothicWoff from "@fonts/monclergothic-book.woff";
import MonclerGothicWoff2 from "@fonts/monclergothic-book.woff2";

const GlobalFonts = () => {
  const { loadFonts } = useContext(LoadContext);

  // load fonts listener
  useEffect(() => {
    if (typeof loadFonts === "function") {
      loadFonts({
        families: ["whyte-ink-trap", "moncler-gothic"],
      });
    }
  }, [loadFonts]);

  return (
    <Global
      styles={css`
        @font-face {
          font-family: "whyte-ink-trap";
          src: local("whyte-ink-trap"),
            url(${WhyteInkTrapWoff2}) format("woff2"),
            url(${WhyteInkTrapWoff}) format("woff");
        }

        @font-face {
          font-family: "moncler-gothic";
          src: local("moncler-gothic"),
            url(${MonclerGothicWoff2}) format("woff2"),
            url(${MonclerGothicWoff}) format("woff");
        }
      `}
    />
  );
};

export default GlobalFonts;
