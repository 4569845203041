import React from "react";
import LoadProvider from "@providers/LoadProvider";
import ResizeProvider from "@providers/ResizeProvider";
import ScrollProvider from "@providers/ScrollProvider";
import PassProtectionProvider from "@providers/PassProtectionProvider";
import GlobalStyles from "@components/GlobalStyles";
import GlobalFonts from "@components/GlobalFonts";

const Layout = ({ children }) => {
  return (
    <LoadProvider>
      <GlobalFonts />
      <GlobalStyles />
      <ResizeProvider>
        <PassProtectionProvider>
          <ScrollProvider>{children}</ScrollProvider>
        </PassProtectionProvider>
      </ResizeProvider>
    </LoadProvider>
  );
};

export default Layout;
