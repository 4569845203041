import React, { createContext, useEffect, useState, useContext } from "react";
import { css } from "@emotion/react";
import { gsap, ScrollTrigger } from "gsap/all";
import { LoadContext } from "@providers/LoadProvider";
import { PassProtectionContext } from "@providers/PassProtectionProvider";
import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks,
} from "body-scroll-lock";

export const ScrollContext = createContext({ scrollContainer: null });
gsap.registerPlugin(ScrollTrigger);

const CONTAINER_ID = "smooth-scroll";

const ScrollProvider = ({ children }) => {
  const [scrollContainer, setScrollContainer] = useState(null);

  const { images, fonts } = useContext(LoadContext);
  const { passwordIsTrue } = useContext(PassProtectionContext);

  const initLocoScroll = () => {
    const container = document.querySelector(`#${CONTAINER_ID}`);
    const Loco = require("@plugins/locomotive/locomotive-scroll.js");
    const locoScroll = new Loco.default({
      el: container,
      smooth: true,
    });

    ScrollTrigger.scrollerProxy(`#${CONTAINER_ID}`, {
      scrollTop(value) {
        return arguments.length
          ? locoScroll.scrollTo(value, 0, 0)
          : locoScroll.scroll.instance.scroll.y;
      },
      getBoundingClientRect() {
        return {
          top: 0,
          left: 0,
          width: window.innerWidth,
          height: window.innerHeight,
        };
      },
      pinType: document.querySelector(`#${CONTAINER_ID}`).style.transform
        ? "transform"
        : "fixed",
    });

    locoScroll.on("scroll", () => {
      ScrollTrigger.update();
    });
    console.log("make it here", container);
    // // set container to be accessible by elements dependent on scroll trigger
    setScrollContainer(container);

    // after everything is set up, refresh() ScrollTrigger and update LocomotiveScroll because padding may have been added for pinning, etc.
    setTimeout(() => {
      console.log("refresh");
      ScrollTrigger.refresh();
    }, 1000);
  };

  useEffect(() => {
    disableBodyScroll(document.querySelector("#placeholder"));

    return () => {
      clearAllBodyScrollLocks();
    };
  }, []);

  useEffect(() => {
    if (images && fonts && passwordIsTrue) {
      enableBodyScroll(document.querySelector("#placeholder"));
      console.log("init smooth scroll");
      //initLocoScroll();
    }
  }, [images, fonts, passwordIsTrue]);

  return (
    <ScrollContext.Provider value={{ scrollContainer: scrollContainer }}>
      <div
        id="smooth-scroll"
        css={css`
          overflow: hidden !important;
        `}
      >
        <div id="placeholder"></div>
        {children}
      </div>
    </ScrollContext.Provider>
  );
};

export default ScrollProvider;
